import { useRef, useState } from "react";
import { DirectUpload } from "@rails/activestorage";
import {
  activeStorageBlobUrl,
  directUploadPath,
} from "@circle-react/helpers/urlHelpers";

export type UploadStatuses = "idle" | "uploading" | "completed" | "error";

interface DirectUploadHookReturn {
  cancelUpload: () => void;
  isError: boolean;
  progress: number;
  reset: () => void;
  response: any;
  status: UploadStatuses;
  uploadFile: (file: any) => void;
}

export const useDirectUpload = (): DirectUploadHookReturn => {
  const [progress, setProgress] = useState<number>(0);
  const [status, setStatus] = useState<UploadStatuses>("idle");
  const [res, setRes] = useState<any>(null);
  const queuedForCancellation = useRef<boolean>(false);

  const uploadFile = (file: any) => {
    const upload = new DirectUpload(file, directUploadPath(), {
      directUploadWillStoreFileWithXHR: xhr => {
        xhr.upload.addEventListener("loadstart", () => {
          setStatus("uploading");
        });
        xhr.upload.addEventListener("progress", e => {
          setProgress((e.loaded / e.total) * 100);
          if (queuedForCancellation.current) xhr.abort();
        });
      },
    });
    upload.create((error, response) => {
      if (error) {
        setStatus("error");
        throw new Error(`Direct upload failed: ${error?.message}`);
      } else {
        setProgress(0);
        setStatus("completed");
        setRes({
          blob_url: activeStorageBlobUrl({
            signed_id: response.signed_id,
            filename: encodeURIComponent(response.filename),
          }),
          ...response,
        });
      }
    });
  };

  const reset = () => {
    setProgress(0);
    setStatus("idle");
    setRes(null);
    queuedForCancellation.current = false;
  };

  const cancelUpload = () => (queuedForCancellation.current = true);

  return {
    cancelUpload,
    isError: status === "error",
    progress,
    reset,
    response: res,
    status,
    uploadFile,
  };
};
