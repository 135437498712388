import { useEffect } from "react";

export const useDetectEscapeKeyPress = (callback, extraDependencies = []) => {
  useEffect(() => {
    const handleKeyPress = event => {
      const key = event.key || event.keyCode;
      if (key === "Escape" || key === "Esc" || key === 27) {
        callback(event);
      }
    };

    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [callback, ...extraDependencies]);
};
