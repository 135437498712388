import { useState } from "react";
import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

export const useCommunityMemberSearch = () => {
  const [communityMembers, setCommunityMembers] = useState([]);

  const searchCommunityMembers = ({
    query = "",
    filters = {},
    fields = ["name^10"],
    ...rest
  }) =>
    fetchRequest(
      internalApi.search.communityMembers.index({
        params: {
          fields,
          query,
          ...rest,
        },
      }),
      {
        body: JSON.stringify({ filters }),
        method: "POST",
      },
    )
      .then(r => r.json())
      .then(data => {
        setCommunityMembers(data.records);
      });

  const resetCommunityMembers = () => {
    setCommunityMembers([]);
  };
  return {
    searchCommunityMembers,
    communityMembers,
    resetCommunityMembers,
  };
};
