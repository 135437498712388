import { useLayoutEffect, useRef, useState } from "react";
import type { RefObject } from "react";

interface Dimensions {
  [key: string]: number;
}

export const useDimensions = <T extends HTMLElement>(): [
  RefObject<T>,
  Dimensions,
] => {
  const ref = useRef<T>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({});

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions(ref.current.getBoundingClientRect().toJSON());
    }
  }, [ref.current]);

  return [ref, dimensions];
};
