/** @deprecated Please use isValidURL instead */
export const DEPRECATED_isValidURL = (str: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol (required)
      "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+" + // domain (with subdomain(s)) or IP v4
      "[a-z\\d]{2,}" + // top-level domain or IP v4 last block
      "(\\:\\d+)?" + // port
      "(\\/[\\-;:&=\\+\\$,\\w\\.]*\\/?)*" + // path, allowing for a trailing slash
      "(\\?[\\-;:&=\\+\\$,\\w\\.%]*)?" + // query string
      "(\\#[\\-/\\w]*)?$", // fragment locator, adjusted to allow more characters
    "i", // case insensitive
  ); // fragment locator

  return !!pattern.test(str);
};

// V2 is using URL class to validate the URL
// but with additional checks that we had in the previous implementation
export const isValidURL = (str: string): boolean => {
  try {
    const url = new URL(str);

    // Do not allow ftp://example.com
    const isValidProtocol =
      url.protocol === "http:" || url.protocol === "https:";

    // Do not allow http://localhost, http://[::1], http://some_invalid_domain
    const domainRegex = /^(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z\d]{2,}$/i;
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValidDomainOrIP =
      domainRegex.test(url.hostname) || ipRegex.test(url.hostname);

    // Do not allow http://user:pass@example.com
    const hasNoAuth = url.username === "" && url.password === "";

    const hasNoSpacesInPath = !url.pathname.includes(" ");

    return (
      isValidProtocol && isValidDomainOrIP && hasNoAuth && hasNoSpacesInPath
    );
  } catch (error) {
    return false;
  }
};
