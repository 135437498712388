import { useEffect, useState } from "react";

export const useMutationObserver = (ref, config = {}) => {
  const [hasElementMutated, setHasElementMutated] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new MutationObserver(() => setHasElementMutated(true));

    observer.observe(ref.current, config);

    return () => observer.disconnect();
  }, [ref, config]);

  return { hasElementMutated, setHasElementMutated };
};
